<template>
  <custom-callout
    class-main="mt-3 rounded"
    :controlled="true"
    :opened="isOpen"
    @click:open="onOpenClose"
  >
    <div class="row py-2 mr-md-0 align-items-center">
      <div
        class="col-12 col-md-3 pl-md-0 py-2 border-right border-right--variant d-flex justify-content-center justify-content-md-start"
      >
        <div class="d-flex flex-column font-weight-bold font-16 pl-lg-5">
          {{ data.contractNumber }}
          <span class="font-weight-normal color-gray font-14">
            <i :class="`fas fa-circle mr-1 font-12 color-${statusColor}`" />
            {{ data.statusName }}
          </span>
        </div>
      </div>
      <div class="col-12 col-md-7 row mx-0">
        <div class="col-12 col-md mt-3 mt-md-0">
          <span class="data-title d-block">Proveedor:</span>
          {{ data.provider | empty }}
        </div>
        <div class="col-12 col-md mt-3 mt-md-0">
          <span class="data-title d-block">Tipo de Servicio:</span>
          {{ data.serviceName | empty }}
        </div>
        <div class="col-12 col-md mt-3 mt-md-0">
          <span class="data-title d-block">Servicio:</span>
          {{ data.serviceType | empty }}
        </div>
      </div>
      <div class="col-12 col-md-2 mt-3 mt-md-0 text-right">
        <drop-down :items="actionItems" />
      </div>
    </div>
    <template #edition>
      <div class="bg-gray p-2 mt-1">
        <div class="bg-white rounded p-3">
          <custom-tab class="custom-tabs" :default-active-tab="activeTab">
            <custom-tab-item lazy title="Información General">
              <general-info :data="generalInfoData" />
            </custom-tab-item>
            <custom-tab-item lazy title="Visitas de Servicio">
              <service-visits :contract-id="data.contractId" />
            </custom-tab-item>
            <custom-tab-item v-if="isCancelTabVisible" lazy title="Cancelar Contrato">
              <cancel-Contract :contract-id="data.contractId" @onRefresh="onRefresh" />
            </custom-tab-item>
          </custom-tab>
        </div>
      </div>
    </template>
  </custom-callout>
</template>

<script>
import CustomCallout from 'custom-callout';
import DropDown from 'dropdown';
import status from '@/views/students/contracts-history/constans/contractStatus';
import statusColors from '@/utils/constants/statusColors';
import CustomTab from 'custom-tabs/src/CustomTab';
import CustomTabItem from 'custom-tabs/src/CustomTabItem';
import GeneralInfo from '@/views/students/contracts-history/components/GeneralInfo.vue';
import ServiceVisits from '@/views/students/contracts-history/components/ServiceVisits.vue';
import CancelContract from '@/views/students/contracts-history/components/CancelContract.vue';
import { capitalize } from '@/utils/filters';
import { getContractDocument as _getContractDocument } from '@/modules/provisional-remedy/api';
import { cancelContract as _cancelContract } from '@/services/api/contractHistory.api';
import { getCurrentAcademicYear } from '@/services/api/academicUtils.api';
import { downloadBlobToFile } from '@/utils/blob';
import alert from '@/mixins/alert.mixin';
import contractStatuses from '@/utils/constants/contractStatuses';


const TabIdx = {
  GeneralInfo: 0,
  ServiceVisits: 1,
  CancelContract:2,
};

export default {
  name: 'HistoryResult',
  components: {
    CustomCallout,
    DropDown,
    CustomTab,
    CustomTabItem,
    GeneralInfo,
    ServiceVisits,
    CancelContract,
  },
  mixins: [alert],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    activeTab: TabIdx.GeneralInfo,
    isOpen: false,
    currentYear: null,
  }),
  computed: {
    generalInfoData() {
      return {
        servicePlace: this.data?.servicePlace,
        specialists: capitalize(this.data?.specialists.join(', ')),
      };
    },
    statusColor() {
      switch (this.data.statusId) {
        case status.New:
          return statusColors.MediumGray;
        case status.Submitted:
          return statusColors.Yellow;
        case status.Aproved:
          return statusColors.Rose;
        case status.Denied:
          return statusColors.Purple;
        case status.Signed:
          return statusColors.GreenLight;
        case status.Canceled:
          return statusColors.Brown;
        case status.Amended:
          return statusColors.Orange;
        default:
          return null;
      }
    },
    actionItems() {
      return [
        {
          label: 'Ver Visitas de Servicio',
          icon: 'fas fa-calendar-check color-green',
          click: () => this.onOpenClose(TabIdx.ServiceVisits),
        },
        {
          label: 'Imprimir Contrato',
          icon: 'fas fa-print color-green',
          click: () => this.onDownloadContractHandler(this.data?.contractId),
        },
        {
          label: 'Cancelar Contrato',
          icon: 'fas fa-times-circle color-green',
          visible:
            this.data?.academicYearId == this.currentYear &&
            !this.data?.hasVisit &&
            this.data?.statusId == contractStatuses.Nuevo,
            click: () => this.onOpenClose(TabIdx.CancelContract),
       
        },
      ];
    },
    isCancelTabVisible() {
        if(this.data?.academicYearId == this.currentYear &&
           !this.data?.hasVisit &&
           this.data?.statusId == contractStatuses.Nuevo)
            return true;
          else
            return false;
    },
  },
  async created() {
    await this.getCurrentAcademicYear();
  },
  methods: {
    onOpenClose(tabIdx) {
      this.activeTab = tabIdx ?? TabIdx.GeneralInfo;
      this.isOpen = !this.isOpen;
    },
    async onDownloadContractHandler(contractId) {
      if (contractId) {
        const { data: documentResult } = await _getContractDocument(
          contractId
        ).catch((err) => {
          if (err.response != null)
            this.ShowToast(
              'Alerta',
              err.response.data.message || 'error',
              'error',
              5000
            );
        });
        if (documentResult) {
          downloadBlobToFile(documentResult);
        }
      }
    },
    async getCurrentAcademicYear() {
      const { data: academicYear } = await getCurrentAcademicYear();
      this.currentYear = academicYear.id;
    },
    async onCancelContractHandler(contractId) {
      if (contractId) {
        const response = await alert.methods.ShowCancelModal(
          'Confirmación',
          `¿Está seguro que desea cancelar el contrato?`,
          'Sí',
          'No'
        );
        if (response) {
          await _cancelContract(contractId)
            .then(() => {
              this.ShowToast(
                'Alerta',
                'El contrato ha sido cancelado exitosamente.',
                'success',
                5000
              );
            })
            .catch((err) => {
              if (err.response != null)
                this.ShowToast(
                  'Alerta',
                  err.response.data.message || 'error',
                  'error',
                  5000
                );
            });
        }
      }
    },
    async onRefresh()
    {
      this.onOpenClose(0)
      this.$emit('onRefresh');
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/v2/brand_variables.scss';

.custom-tabs {
  ::v-deep {
    .tab-content {
      padding: 1rem 1.5rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .border-right--variant {
    border-right: unset !important;
  }
  .custom-tabs {
    ::v-deep {
      .tab-content {
        padding: 0;
      }
    }
  }
}
</style>
