<template>
  <div>
    <custom-result-not-found v-if="results.length === 0" />
    <template v-else>
      <div
        v-for="(row, idx) of results"
        :key="`visit-${idx}`"
        class="row mx-0 bg-secondary-3 rounded py-3 px-4 mb-2"
      >
        <div class="col-12 col-md-3 mb-3 mb-md-0">
          <span class="data-title d-block">{{ visitMode(row) }}:</span>
          {{ visitType(row) }}
        </div>
        <div class="col-12 col-md-2 mb-3 mb-md-0">
          <span class="data-title d-block">Fecha:</span>
          {{ row.date | formatDate }}
        </div>
        <div class="col-12 col-md mb-3 mb-md-0">
          <span class="data-title d-block">Horario:</span>
          <span v-html="formatedHour(row)" />
        </div>
        <div class="col-12 col-md mb-3 mb-md-0">
          <span class="data-title d-block">Especialista:</span>
          {{ row.specialist }}
        </div>
      </div>

      <pagination
        v-if="pagination.totalItemCount > defaultPageSize"
        :data="pagination"
        show-text
        @pagination-go-page="goToPage"
      />
    </template>
  </div>
</template>

<script>
import Pagination from 'pagination';
import CustomResultNotFound from 'custom-result-not-found';
import { getServiceVisits } from '@/services/api/contractHistory.api';
import { capitalize } from '@/utils/filters';
import moment from 'moment';

export default {
  name: 'ServiceVisits',
  components: { Pagination, CustomResultNotFound },
  props: {
    contractId: {
      type: Number,
      default: () => 0,
    },
    defaultPageSize: {
      type: Number,
      default: () => 5,
    },
  },
  data: () => ({
    pagination: {
      currentPage: 1,
      totalItemCount: 0,
      pageCount: 1,
      count: 0,
    },
    results: [],
  }),
  computed: {
    visitMode() {
      return ({ virtualTherapy }) =>
        virtualTherapy ? 'Virtual' : 'Presencial';
    },
    visitType() {
      return ({ visitCompensatory }) =>
        visitCompensatory ? 'Visita Regular' : 'Visita de Reposición';
    },
    formatedHour() {
      return ({ startTime, duration, endTime }) => {
        return `${this.formatHour(startTime)}-${this.formatHour(
          endTime
        )} <span class="font-weight-bold">(${
          duration.totalMinutes
        } mins)</span>`;
      };
    },
  },
  async created() {
    await this.goToPage(1);
  },
  methods: {
    formatHour(timespan) {
      return moment({
        hour: timespan.hours,
        minute: timespan.minutes,
        second: timespan.seconds,
      }).format('hh:mm a');
    },
    async goToPage(page) {
      this.pagination.currentPage = page;
      const { data } = await getServiceVisits(
        this.contractId,
        this.pagination.currentPage,
        this.defaultPageSize
      );
      this.results = data.data.map((d) => ({
        ...d,
        specialist: capitalize(d.specialist),
      }));
      this.pagination = {
        totalItemCount: data.totalItemCount,
        pageCount: data.pageCount,
        currentPage: data.currentPage,
        count: data.count,
      };
    },
  },
};
</script>
