import { http } from '@/core/api/';

export default {
  async getHRrequestAcademicYears() {
    const { data } = await http.get(
      '/api/academicyears/academicyearfromin?minYearId=25&yearType=FUTURE'
    );
    return data;
  },
  getRegions(selectedAcademicYear) {
    return http.get(
      `api/schools/user/regions?academicyear=${selectedAcademicYear.id}`
    );
  },
  getSampleInventoryStatuses() {
    return http.get(`api/code/getallsampleinventorystatuses`);
  },
  getDistricts(selectedRegion, selectedAcademicYear) {
    return http.get(
      `api/schools/user/districts?regionid=${selectedRegion.id}&academicyear=${selectedAcademicYear.id}`
    );
  },
  getSchools(selectedDistrict, selectedAcademicYear) {
    return http.get(
      `api/schools/user/schools?districtid=${selectedDistrict.id}&academicyear=${selectedAcademicYear.id}`
    );
  },
  getEduEsp(properties) {
    return http.get('api/code/geteduespcodes', {
      params: { ...properties },
    });
  },
  getGrades(properties) {
    return http.get('api/books/grades', {
      params: { ...properties },
    });
  },
};

export const getCurrentAcademicYear = () => {
  return http.get('/api/academicyear/current');
};

export const getRpContractsAcademicYears = (studentSieId) =>
  http.get(`api/academicyear/withrpcontracts/${studentSieId}`);
