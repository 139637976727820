const contractStatuses = {
  Nuevo: 1,
  Sometido: 2,
  ContratoAprobado: 3,
  ContratoDenegado: 4,
  InformaciondeContratoAprobada: 5,
  InformaciondeContratoDenegada: 6,
  ContratoFirmado: 7,
  ContratoCancelado: 8,
  ContratoEnmendado: 9,
};

export default contractStatuses;
