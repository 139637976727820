<template>
  <div>
    <filter-manager
      ref="filterComponent"
      v-model="filters"
      class="mb-4"
      :display-advanced="false"
      :filters="filtersConfig"
      @search="onSearchHandler"
    />
    <custom-separator class="my-4" text="Resultado de Contratos" />
    <custom-result-not-found v-if="results.length === 0" />
    <history-result
      v-for="(row, idx) of results"
      :key="`result-${idx}`"
      :data="row"
      @onRefresh="onRefresh"
    />
    <pagination
      v-if="pagination.totalItemCount > pagination.pageSize"
      :data="pagination"
      show-text
      @pagination-go-page="goToPage"
    />
  </div>
</template>

<script>
import Pagination from 'pagination';
import FilterManager from 'FilterManager';
import CustomSeparator from 'custom-separator';
import CustomResultNotFound from 'custom-result-not-found';
import HistoryResult from '@/views/students/contracts-history/components/HistoryResult.vue';
import { getRpContractsAcademicYears } from '@/services/api/academicUtils.api';
import {
  getAvailableStatus,
  searchContract,
} from '@/services/api/contractHistory.api';

export default {
  name: 'StudentContractsHistory',
  components: {
    FilterManager,
    CustomSeparator,
    CustomResultNotFound,
    Pagination,
    HistoryResult,
  },
  props: {
    studentSieId: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    pagination: {
      currentPage: 1,
      totalItemCount: 0,
      pageCount: 1,
      count: 0,
      pageSize: 10,
    },
    filters: {},
    filterOptions: {
      academicYears: [],
      statuses: [],
    },
    results: [],
  }),
  computed: {
    filtersConfig() {
      return [
        {
          name: 'academicYear',
          component: 'CustomDropDown',
          options: this.filterOptions.academicYears,
          placeholder: 'Seleccione Año Académico',
          fieldName: 'description',
          fieldKey: 'id',
          class: 'col-12 col-md-4',
          initialValue: this.filters.service,
        },
        {
          name: 'status',
          component: 'CustomDropDown',
          options: this.filterOptions.statuses,
          placeholder: 'Seleccione Estatus',
          fieldName: 'name',
          fieldKey: 'id',
          class: 'col-12 col-md-4',
        },
        {
          name: 'searchNumber',
          component: 'CustomInput',
          icon: 'fa fa-search color-orange',
          leftIcon: true,
          placeholder: 'Escriba Número de Contrato o Solicitud',
          class: 'col-12 col-md-4',
        },
      ];
    },
  },
  async created() {
    await Promise.all([this.initFilters(), this.onSearchHandler()]);
  },
  methods: {
    async initFilters() {
      const { data: academicYears } = await getRpContractsAcademicYears(
        this.studentSieId
      );
      const { data: statuses } = await getAvailableStatus();

      this.filterOptions = { academicYears, statuses };
    },
    async goToPage(page) {
      this.pagination.currentPage = page;
      await this.onSearchHandler();
    },
    async onSearchHandler() {
      const filters = { ...this.filters };
      const params = {
        studentSieId: +this.$route.params.studentSieId,
        academicYearId: filters.academicYear?.id,
        statusId: filters.status?.id,
        search: filters.searchNumber,
      };
      await searchContract(
        params,
        this.pagination.currentPage,
        this.pagination.pageSize
      ).then(({ data }) => {
        this.results = [...data.data];
        this.pagination = {
          ...this.pagination,
          totalItemCount: data.totalItemCount,
          pageCount: data.pageCount,
          currentPage: data.currentPage,
          count: data.count,
        };
      });
    },
    async onRefresh()
    {
       await this.onSearchHandler();
    }
  },
};
</script>
