import { http } from '@/core/api/';

const URL = 'api/rpcontract';

export const getAvailableStatus = () => http.get(`${URL}/availablestatus`);

export const getServiceVisits = (contractId, pageIndex, pageSize) =>
  http.get(`${URL}/servicevisits`, {
    params: {
      contractId,
      pageIndex,
      pageSize,
    },
  });

export const searchContract = (params, pageIndex, pageSize) =>
  http.post(
    `${URL}/searchcontract`,
    { ...params },
    {
      params: {
        pageIndex,
        pageSize,
      },
    }
  );

  export const cancelContract = (params) =>
  http.put(
    `${URL}/cancel`,
    { ...params }
  );

