<template>
   <div class="rounded p-2">
      <validation-observer ref="addCommentRef" novalidate>
      <div class="row col-lg-12">
        <div class="col-12 col-md mb-12 mb-md-12">
          <span class="d-block">¿Está seguro que desea cancelar el contrato?</span>
        </div>
      </div> 
      <div class="row col-lg-12">
        <div class="col-12 col-md mb-12 mb-md-0">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-text-area
              id="add-comment-text-id"
              class="color-gray-6"
              label="Comentario:"
              :maxlength="3000"
              v-model="model.statusTransitionComment"
              show-char-count
              custom-error-msg="El comentario es requerido."
              :error="errors[0]"
            />
          </validation-provider>
        </div>
      </div>
      <div class="row col-lg-2">
          <action-button
              id="add-comment-cancel"
              :i="`fas fa-times-circle`"
              :responsive="true"
              variant="outline-danger m-1"
              @click="onCancelContractHandler()"
              >
            Cancelar Contrato
            </action-button>
      </div>
    </validation-observer>
   </div>
 </template>

<script>
import CustomTextArea from 'CustomTextArea';
import ActionButton from 'ActionButton';
import alert from '@/mixins/alert.mixin';
import { cancelContract as _cancelContract } from '@/services/api/contractHistory.api';

const modelInitialState = () => ({
  statusTransitionComment: null,

});

export default {
  name: 'CancelContract',
  components: {
    CustomTextArea,
    ActionButton,
  },
  mixins: [alert],
  props: {
    contractId: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({ model: modelInitialState() }),
  methods: {
    async onCancelContractHandler() {
      const isValid = await this.$refs.addCommentRef.validate();
      if (!isValid) {
        this.ShowToast(
          'Información Incompleta',
          'Favor completar la información solicitada para poder continuar.',
          'error'
        );
        return;
      }
      const params = {
        id: this.contractId,
        statusTransitionComment: this.model.statusTransitionComment,
      };

      if (this.contractId) 
      {
        await _cancelContract(params)
          .then(() => {
            this.ShowToast(
              'Alerta',
              'El contrato ha sido cancelado exitosamente.',
              'success',
              5000
            );
            this.$emit('onRefresh');
          })
          .catch((err) => {
            if (err.response != null)
              this.ShowToast(
                'Alerta',
                err.response.data.message || 'error',
                'error',
                5000
              );
          });
      }
    },
   },
};
</script>
