<template>
  <div v-if="studentSieId">
    <page-header
      :actions="headerActions"
      :page="{
        icon: 'fas fa-history',
        title: 'Historial de Contratos',
        routes: ['Inicio', 'Historial de Contratos'],
      }"
    />

    <student-contracts-history :student-sie-id="studentSieId" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import StudentContractsHistory from './components/StudentContractsHistory';
import { providerDirectoryType } from '@/enums/provider-directory-type.js';
import parameters from '@/utils/constants/sysParamCodeTypes/parameters';
import {
  getByParameterName as _getByParameterName,
} from '@/api/sysparameter.api.js';

export default {
  name: 'ContractsHistory',
  components: { PageHeader, StudentContractsHistory },
  props: { studentSieId: { type: [String, Number], default: null } },
  computed: {
    headerActions() {
      return [
        {
          text: 'Regresar',
          icon: 'fas fa-arrow-circle-left fa-fw',
          variant: 'outline-success',
          click: () => this.$router.push({ name: 'home' }),
        },
        {
          text: 'Directorio proveedores',
          icon: 'fas fa-link fa-fw',
          variant: 'outline-success',
          visible: this.userHasPermissions(
            'parents.provisionalremedy.browsedirectory'
          ),
          click: () => this.goProviderDirectoryView(),
        },
      ];
    }
  },
  methods: {
    async goProviderDirectoryView() {
      const { data: parameter } = await _getByParameterName(parameters.PublicPortalUrl);
      window.open(
        `${parameter.value}provider-directory/${providerDirectoryType.USPQRP}`,
        '_blank'
      );
    },
  },
};
</script>
